import React from 'react';
import styles from '../NavBar/Navbar.module.css';
import {useNavigate} from "react-router-dom";
import tokenService from "../Util/tokenService";
import useAuth from "../../hooks/useAuth";

const DropDownProfile = ({user, setOpenProfile}) => {
    const navigate = useNavigate();
    const {setAuth} = useAuth();

    const navigateToProfile = () => {
        setOpenProfile(false);
        navigate('/partner/profile');
    }

    const logOut = async () => {
        setAuth(null);
        tokenService.clear()
        setOpenProfile(false);
        navigate('/login');
    }

    if (!user) {
        return <div>Loading...</div>;
    }

    return (
        <div className={styles.DropDownProfile}>
            {
                !user && <div>Loading...</div>
            }

            {user &&
                <div>
                    <h3>{`${user.name} ${user.surname}`}</h3>

                    <ul>
                        <li onClick={navigateToProfile}>Мой профиль</li>
                        <li onClick={logOut}>Выйти</li>
                    </ul>
                </div>
            }
        </div>
    )
}

export default DropDownProfile;