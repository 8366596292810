import styles from "../css/ProjectsBlock.module.css";
import React, {useEffect, useState} from "react";
import ProjectCard from "./ProjectCard";
import ProjectInfoModal from "./modal/ProjectInfoModal";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

function ProjectsBlock() {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [projectDetailsList, setProjectDetailsList] = useState(null);
    const [newProjectAdded, setNewProjectAdded] = useState(false); // State to trigger useEffect when new data is added

    const axiosPrivate = useAxiosPrivate();

    const openAddProjectModal = () => {
        setIsOpen(true);
    }

    useEffect(() => {
        const fetchProjects = async () => {
            const response = await axiosPrivate.get('api/partner/v1/projects')
            return response.data;
        }

        const response = fetchProjects();
        response.then(data => {
            setProjectDetailsList(data.projectDetails);
        }).catch(error => {
            if (error.response) {
                console.log(error.response.data.message);
            }
        });
    }, [newProjectAdded]);

    if (!projectDetailsList) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <button className={styles.addButton} onClick={openAddProjectModal}>
                Добавить проект
            </button>

            {
                projectDetailsList.length === 0 &&
                <div className={styles.noProjects}>
                    У вас пока нет проектов
                </div>
            }

            <div className={styles.worksTable}>
                {projectDetailsList.map(pd => {
                    return (
                        <ProjectCard key={pd.project.id} project={pd.project} coverImage={pd.coverImage}/>
                    );
                })}
            </div>

            <ProjectInfoModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen}
                              newProjectAdded={newProjectAdded} setNewProjectAdded={setNewProjectAdded}/>

        </div>
    );
}

export default ProjectsBlock;
