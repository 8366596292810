import React from 'react';
import styles from './Cards.module.css';
import CardItem from './CardItem';

function Cards({businesses}) {
    return (
        <section id="designers" className={styles.CardsSection}>
            <h2 className={styles}>Дизайнеры</h2>

            <div className={styles.BusinessList}>
                {businesses.map((business) => {
                    return <CardItem business={business} key={business.id}/>;
                })}
            </div>

        </section>
    );
}

export default Cards;
