import React from 'react';
import styles from './Cards.module.css';
import eyeIcon from './eye.svg';
import {Link} from "react-router-dom";

function CardItem({business}) {
    const getBuildingTypes = (premisesTypes) => {
        let buildingTypes = '';
        premisesTypes.forEach((premisesType, index) => {
            if (index === premisesTypes.length - 1) {
                buildingTypes += premisesType.name;
            } else {
                buildingTypes += premisesType.name + ', ';
            }
        });
        return buildingTypes;
    }

    return (
        <div className={styles.Card}>
            <div className={styles.imageContainer}>
                <img src={`${business?.projectImages[0]?.url}`}
                     alt=""/>
            </div>

            <div className={styles.frame10}>

                <div className={styles.info}>
                    <span className={styles.name}>{business.specialist.name} {business.specialist.surname}</span>

                    <p className={styles.CardPrice}>{business.price.formattedPrice}</p>

                    <div className={styles.CardBio}>
                        <span>{business.city.name}</span>
                        <span>Опыт: {business.specialist.experience ? business.specialist.experience : 'Не указано'}</span>
                        <span>{getBuildingTypes(business.premisesTypes)}</span>
                    </div>
                </div>

                <div className={styles.frame11}>
                    <div className={styles.ViewsCount}>
                        <img src={eyeIcon} alt="Количество просмотров"/>
                        <span>{business.viewsCount}</span>
                    </div>

                    <Link to={'/specialists/' + business.id}>
                        <button className={styles.moreButton}>
                            Подробнее
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
}

// CardItem.propTypes = {
//     business: {
//         id: PropTypes.number.isRequired,
//         specialist: {
//             name: PropTypes.string.isRequired,
//             surname: PropTypes.string.isRequired,
//             experience: PropTypes.string
//         },
//         city: {
//             name: PropTypes.string.isRequired,
//         },
//         viewsCount: PropTypes.number.isRequired,
//         price: {
//             price: PropTypes.number,
//             priceMax: PropTypes.number,
//             formattedPrice: PropTypes.string.isRequired,
//         },
//         premisesTypes: [
//             {
//                 id: PropTypes.number.isRequired,
//                 name: PropTypes.string.isRequired,
//             }
//         ]
//     }
// };

export default CardItem;
