import React, {useEffect, useState} from 'react';
import './App.css';
import {Route, Routes} from 'react-router-dom';
import Navbar from "../NavBar/Navbar";
import Home from "../Home/Home";
import AboutUs from "../AboutUs/AboutUs";
import Specialist from "../Specialist/Specialist";
import Footer from "../Footer/Footer";
import Login from "../Login/Login";
import PartnerProfile from "../PartnerProfile/PartnerProfile";
import RequireAuth from "../Login/RequireAuth";
import SignUp from "../Login/SignUp";
import ProjectPage from "../PartnerProfile/projects/ProjectPage";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const App = () => {
    const axiosPrivate = useAxiosPrivate();
    const {auth} = useAuth();
    const [profile, setProfile] = useState(null);
    useEffect(() => {
        const getProfile = async () => {
            if (!auth || !auth.accessToken) {
                return;
            }

            try {
                const response = await axiosPrivate.get('api/partner/v1/profile')
                setProfile(response.data);
                console.log(response.data);
            } catch (err) {
                console.error(err);
            }
        }

        getProfile();
    }, [auth]);

    return (
        <>
            <Navbar auth={auth} profile={profile}/>
            <Routes>
                <Route path='/'  element={<Home/>}/>
                <Route path='/about-us' element={<AboutUs />}/>
                <Route path='/login' element={<Login />}/>
                <Route path={'/register'} element={<SignUp/>}/>
                <Route path='/specialists/:specialistId' element={<Specialist/>}/>
                <Route element={<RequireAuth allowedRoles={'SPECIALIST'}/>}>
                    <Route path='/partner/profile' element={<PartnerProfile profile={profile} setProfile={setProfile}/>}/>
                    <Route path='/partner/projects/:projectId' element={<ProjectPage/>}/>
                </Route>
            </Routes>

            <Footer/>
        </>
    );

}

export default App;