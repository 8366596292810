import React from 'react';
import styles from './Footer.module.css';
import logo from '../NavBar/abdiz-logo.svg';

function Footer() {
    return (
        <div className={styles.FooterSection}>
            <div className={styles.FooterContent}>

                {/* LEFT */}
                <div className={styles.info}>
                    <img src={logo}/>
                    <span className={styles.Address}>
                        г. Алматы, ул. Абая 123
                    </span>

                    <span className={styles.Copyright}>
                        © Abdiz.kz 2024
                    </span>
                </div>

                {/* RIGHT */}
                <div className={styles.supportInfo}>
                    <span className={styles.TechSupport}>
                        Служба поддержки:
                    </span>

                    <span className={styles.Email}>
                        support@abdiz.kz
                    </span>
                </div>
            </div>
        </div>
    );
}

export default Footer;
