import React, {useEffect, useState} from 'react';
import Cards from '../Card/Cards';
import HeroSection from "../HeroSection/HeroSection";
import specialistService from "../../api/specialistService";

function Home() {
    const [businesses, setBusinesses] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const response = specialistService.getSpecialistsCards();
            response.then(jsonData => {
                setBusinesses(jsonData['specialistCards']);
            });
        };
        fetchData();
    }, []);

    return (
        <div style={{backgroundColor: '#F7F7F7'}}>
            <HeroSection/>
            <Cards businesses={businesses}/>
        </div>
    );
}

export default Home;
