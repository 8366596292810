import React, {useEffect, useState} from "react";

import DataTable from "react-data-table-component";
import styles from "../css/ProjectsBlock.module.css";
import ServicePackageModal from "./ServicePackageModal";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const ServicesBlock = () => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [servicePackagesChanged, setServicePackagesChanged] = useState(false);
    const [servicePackagesList, setServicePackagesList] = useState([]);
    const [selectedServicePackage, setSelectedServicePackage] = useState(null);

    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        const fetchServicePackages = async () => {
            const response = await axiosPrivate.get('api/partner/v1/service-packages')
            return response.data;
        }

        const response = fetchServicePackages();
        response.then(data => {
            setServicePackagesList(data.servicePackages);
        }).catch(error => {
            if (error.response) {
                console.log(error.response.data.message);
            }
        });
    }, [servicePackagesChanged]);


    const openAddServicesModal = () => {
        setSelectedServicePackage(null);
        setIsOpen(true);
    }

    const onServicePackageRowClicked = (row) => {
        setSelectedServicePackage(row);
        setIsOpen(true);
    }

    const columns = [
        {
            name: 'Название',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Услуги',
            selector: row => (
                <ol>
                    {row.services.map((serviceName, index) => (
                        <li key={index}>{serviceName}</li>
                    ))}
                </ol>
            ),
            sortable: true,
        },
        {
            name: 'Цена',
            selector: row => row.price,
            sortable: true,
        },
        {
            name: 'Действия',
            selector: row => (
                <button onClick={() => onServicePackageRowClicked(row)}>Изменить</button>
            ),
        }
    ]

    return (
        <div>
            <button className={styles.addButton} onClick={openAddServicesModal}>
                Добавить пакет услуг
            </button>

            <DataTable
                columns={columns}
                data={servicePackagesList}>

            </DataTable>

            <ServicePackageModal
                servicePackage={selectedServicePackage}
                modalIsOpen={modalIsOpen} setIsOpen={setIsOpen}
                servicePackagesChanged={servicePackagesChanged} setServicePackagesChanged={setServicePackagesChanged}
            />
        </div>
    );
}

export default ServicesBlock;