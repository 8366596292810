import React, {useState, useEffect, useCallback, useRef} from "react";
import styles from "./css/WorkDetails.module.css";
import Modal from "react-modal";
import closeIcon from "./icons/close.svg";
import PrevIcon from "./icons/PrevIcon";
import NextIcon from "./icons/NextIcon";

const customStyles = {
    content: {
        maxWidth: window.outerWidth <= 480 ? '100%' : '80%',
        width: '100%',
        borderRadius: window.outerWidth <= 480 ? '0' : '16px',
        padding: window.outerWidth <= 480 ? '16px   ' : '32px',
        margin: window.outerWidth <= 480 ? '0px' : 'auto',
        overflow: 'hidden',
        maxHeight: window.outerWidth <= 480 ? '100%' : '80%',
        inset: window.outerWidth <= 480 ? '0' : '40px',
    },
    overlay: {
        zIndex: 1000,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    }
};

function WorkDetails({project, modalIsOpen, setIsOpen}) {

    const [activeImage, setActiveImage] = useState(0);
    const [activeRoomType, setActiveRoomType] = useState(0);
    const rooms = project.rooms;
    const roomRefs = useRef([]); // Create a ref to store references to each room element

    const nextImage = useCallback(() => {
        setActiveImage((prev) =>
            prev >= rooms[activeRoomType]?.images.length - 1 ? 0 : prev + 1
        );
    }, [rooms, activeRoomType]);

    const prevImage = useCallback(() => {
        setActiveImage((prev) =>
            prev <= 0 ? rooms[activeRoomType]?.images.length - 1 : prev - 1
        );
    }, [rooms, activeRoomType]);

    const handleKeyDown = useCallback((event) => {
        if (!rooms) {
            return;
        }

        if (event.key === "ArrowLeft") {
                prevImage();
            } else if (event.key === "ArrowRight") {
                nextImage();
            }
        },
        [nextImage, prevImage] // Dependencies ensure memoized references
    );

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyDown]); // Ensure the listener is cleaned up properly

    // Check if myObject exists before accessing its properties
    if (!rooms) {
        return <div style={{display: 'none'}}>Loading...</div>;
    }

    function afterOpenModal() {
        // Disable scrolling when modal is open
        document.body.style.overflow = "hidden";
    }

    function closeModal() {
        setIsOpen(false);
        setActiveImage(0);
        setActiveRoomType(0);

        // Re-enable scrolling when modal is closed
        document.body.style.overflow = "auto";
    }

    const changeActiveRoomType = (index) => {
        setActiveRoomType(index);
        setActiveImage(0);

        // Scroll the clicked room into view
        roomRefs.current[index]?.scrollIntoView({
            behavior: "smooth",
            block: "nearest", // Align the item to the nearest edge of the visible container
            inline: "start", // Scroll horizontally to align the item to the start
        });
    }

    return (
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel={project.name}
        >
            <div className={styles.MainContainer}>
                <div className={styles.Header}>
                    <h1>
                        {project.name}
                    </h1>

                    <h2>
                        {project.squareMeters} кв.м
                    </h2>

                    <img src={closeIcon} alt="Close button"
                         className={styles.closeButton}
                         onClick={closeModal}/>
                </div>

                <div className={styles.RoomTypes}>
                    {
                        rooms.map((roomType, index) => {
                            return (
                                <div key={roomType.id}
                                     ref={(el) => (roomRefs.current[index] = el)}
                                     className={`${styles.RoomType} ${activeRoomType === index ? styles.active : styles.inactive}`}
                                     onClick={() => {
                                         changeActiveRoomType(index);
                                     }}>
                                    <h3>{roomType.roomType.name}</h3>
                                </div>
                            )
                        })
                    }
                </div>

                {/* Pictures for desktop START */}
                <div className={styles.WorkImage}>
                    <PrevIcon onClick={prevImage}/>

                    <img
                        className={styles.mainImage}
                        src={`${rooms[activeRoomType]?.images[activeImage]?.url}`}
                        alt={rooms[activeRoomType]?.roomType?.name}/>

                    <NextIcon onClick={nextImage}/>
                </div>

                <div className={styles.CounterContainer}>
                    {activeImage + 1}/{rooms[activeRoomType]?.images.length}
                </div>
                {/* Pictures for desktop END */}

                {/* Pictures for mobile START */}
                <div className={styles.MobileVersionImages}>
                    {rooms[activeRoomType]?.images.map((item, index) => (
                        <img
                            key={index}
                            className={styles.mainImage}
                            src={`${item.url}`}
                            alt={rooms[activeRoomType].roomType.name}/>
                    ))}
                </div>
                {/* Pictures for mobile END */}

            </div>
        </Modal>
    );
}

export default WorkDetails;


