import React, {useEffect, useState} from 'react';
import styles from "./css/PartnerProfile.module.css";
import ProfileBlock from "./profile/ProfileBlock";
import ProjectsBlock from "./projects/ProjectsBlock";
import ServicesBlock from "./services/ServicesBlock";
import {useLocation, useNavigate} from "react-router-dom";

const PROJECTS_PAGE = "works";
const SERVICES_PAGE = "services";

function PartnerProfile({profile, setProfile}) {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const [pageName, setPageName] = useState(PROJECTS_PAGE);

    const navigateToPage = (page) => {
        setPageName(page);
        queryParams.set('page', page);
        navigate(`${location.pathname}?${queryParams.toString()}`, {replace: true});
    }

    useEffect(() => {
        const currentPage = queryParams.get('page');
        if (currentPage === SERVICES_PAGE) {
            setPageName(SERVICES_PAGE);
        } else {
            setPageName(PROJECTS_PAGE);
        }
    }, []);

    if (!profile) {
        return <div>Loading...</div>;
    }

    return (
        <div className={styles.mainContainer}>
            <h2>Мой профиль</h2>

            <div className={styles.innerContainer}>
                <div className={styles.container1}>
                    <ProfileBlock profile={profile} setProfile={setProfile}/>
                </div>

                <div className={styles.container2}>

                    <div className={styles.container2Header}>
                        <div className={pageName === PROJECTS_PAGE ? styles.selected : ''}
                             onClick={() => navigateToPage(PROJECTS_PAGE)}>
                            Мои работы
                        </div>

                        <div className={pageName === SERVICES_PAGE ? styles.selected : ''}
                             onClick={() => navigateToPage(SERVICES_PAGE)}>
                            Услуги
                        </div>
                    </div>

                    <div className={styles.container2Divider}/>

                    {
                        pageName === PROJECTS_PAGE
                        &&
                        <ProjectsBlock/>
                    }

                    {
                        pageName === SERVICES_PAGE
                        &&
                        <div>
                            <ServicesBlock/>
                        </div>
                    }
                </div>
            </div>


        </div>
    );
}

export default PartnerProfile;