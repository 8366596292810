import {Navigate, Outlet, useLocation} from "react-router-dom";
import useAuth from '../../hooks/useAuth';
import {jwtDecode} from "jwt-decode";

const RequireAuth = ({ allowedRoles }) => {
    const { auth } = useAuth();
    const location = useLocation();

    const decodedToken = auth?.accessToken && jwtDecode(auth?.accessToken);
    const userRole = decodedToken?.scope;

    return (
        allowedRoles?.includes(userRole)
            ? <Outlet />
            : auth?.user
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default RequireAuth;