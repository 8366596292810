import React, {useEffect, useState} from 'react'
import styles from "./Login.module.css";
import {Link, useNavigate} from "react-router-dom";
import {DevTool} from "@hookform/devtools";
import useAuth from "../../hooks/useAuth";
import {useForm} from "react-hook-form";
import {InputMask} from '@react-input/mask';
import authService from "../../api/authService";
import tokenService from "../Util/tokenService";
import {PhoneUtils} from "../Util/phoneUtils";

function SignUp() {
    const {auth, setAuth} = useAuth();
    const [step, setStep] = useState(1); // Track the current step
    const [signUpData, setSignUpData] = useState({}); // Store the data from the first step
    const [showPassword, setShowPassword] = useState(false);

    const navigate = useNavigate();
    const {
        register, control, setError,
        handleSubmit, formState
    }
        = useForm({});

    useEffect(() => {
        // if user is auth
        if (auth) {
            navigate('/partner/profile');
        }
    }, []);

    const {errors} = formState;

    const onSubmitStep1 = (data) => {
        if (data.password !== data.password2) {
            setError('password2', {type: 'manual', message: 'Пароли не совпадают'});
            return;
        }

        setSignUpData(data);
        setStep(2);

        const response = authService.requestEmailCode(data.email, 'SIGN_UP');
        response.then(data => {
            console.log(data);
        }).catch(error => {
            if (error.response) {
                setError('confirmationCode', {type: 'manual', message: error.response.data.message});
            }
        });
    }

    const onSubmitStep2 = (data) => {
        signUpData.code = data.confirmationCode;
        signUpData.role = 'SPECIALIST';

        const response = authService.signUp(signUpData);
        response.then(data => {
            tokenService.save(data);
            setAuth(data);

            navigate('/partner/profile');
        }).catch(error => {
            if (error.response) {
                setError('confirmationCode', {type: 'manual', message: error.response.data.message});
            }
        });
    }

    const togglePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState);
    };

    return (
        <div className={styles.loginContainer}>
            <h1 className={styles.title}>
                {step === 1 ? 'РЕГИСТРАЦИЯ' : 'Подтвердите email'}
            </h1>


            {step === 1 &&
                <div id="step1">
                    <form onSubmit={handleSubmit(onSubmitStep1)} noValidate>

                        {/* Name */}
                        <div className={styles.formControl}>
                            <input id='name' type='text' placeholder={'Имя'}
                                   style={{textTransform: 'capitalize'}}
                                   {...register('name', {
                                       required:
                                           'Имя не может быть пустым'
                                   })} />
                            <p className={styles.error}> {errors.name?.message}</p>
                        </div>

                        {/* Surname */}
                        <div className={styles.formControl}>
                            <input id='surname' type='text' placeholder={'Фамилия'}
                                   style={{textTransform: 'capitalize'}}
                                   {...register('surname')} />
                            <p className={styles.error}> {errors.surname?.message}</p>
                        </div>

                        {/* Phone */}
                        <div className={styles.formControl}>
                            <InputMask id='phone' placeholder={'Номер телефона'}
                                       type={'tel'}
                                       mask="+7 (___) ___-__-__" replacement={{_: /\d/}}
                                       {...register('phone', {
                                           validate: {
                                               isNumbersCorrect: (value) =>
                                                   PhoneUtils.isValidPhoneNumber(value) || 'Введите номер телефона полностью'
                                           }
                                       })} />
                            <p className={styles.error}> {errors.phone?.message}</p>
                        </div>

                        {/* Email */}
                        <div className={styles.formControl}>
                            <input id='email' type='email' placeholder={'Почта'}
                                   {...register('email', {
                                       required:
                                           'Email не может быть пустым'
                                   })} />
                            <p className={styles.error}> {errors.email?.message}</p>
                        </div>

                        {/* Password */}
                        <div className={styles.formControl}>
                            <div className={styles.passwordContainer}>
                                <input id='password' placeholder={'Пароль'}
                                       type={showPassword ? 'text' : 'password'}
                                       {...register('password', {
                                           required: 'Введите пароль',
                                       })} />

                                <span onClick={togglePasswordVisibility}>
                                    {showPassword ? 'Скрыть' : 'Показать'}
                                </span>
                            </div>
                            <p className={styles.error}> {errors.password?.message}</p>
                        </div>

                        {/* Repeat password */}
                        <div className={styles.formControl}>
                            <div className={styles.passwordContainer}>
                                <input id='password2' placeholder={'Повторите пароль'}
                                       type={showPassword ? 'text' : 'password'}
                                       {...register('password2', {
                                           required: 'Повторите пароль'
                                       })} />

                                <span onClick={togglePasswordVisibility}>
                                    {showPassword ? 'Скрыть' : 'Показать'}
                                </span>
                            </div>

                            <p className={styles.error}> {errors.password2?.message}</p>
                        </div>

                        <button type='submit'>
                            Зарегистрироваться
                        </button>
                    </form>

                    <Link to='/login' className={styles.link}>
                        Вход в аккаунт
                    </Link>
                </div>
            }

            {step === 2 &&
                <div id="step2">

                    <p className={styles.step2Title}>
                        Введите код который был отправлен на Вашу почту
                        <span>{signUpData.email}</span>
                    </p>

                    <form onSubmit={handleSubmit(onSubmitStep2)} noValidate>

                        <div className={styles.formControl}>
                            <input id='confirmationCode' type='text' placeholder={'Код подтверждения'}
                                   {...register('confirmationCode', {
                                       required:
                                           'Код подтверждения не может быть пустым'
                                   })} />
                            <p className={styles.error}> {errors.confirmationCode?.message}</p>
                        </div>

                        <button type='submit'>
                            Завершить регистрацию
                        </button>
                    </form>

                    <span className={styles.link} onClick={() => setStep(1)}>
                        Назад
                    </span>
                </div>
            }


            <DevTool control={control}/>
        </div>
    )
}

export default SignUp;