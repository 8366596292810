import styles from "./ProfileBlock.module.css";
import React, {useState} from "react";
import avaPlaceholder from "../icons/ava_placeholder.png";
import ImageModal from "../projects/image/ImageModal";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import ProfileModal from "./ProfileModal";
import {PhoneUtils} from "../../Util/phoneUtils";
import Switch from "react-switch";

function ProfileBlock({profile, setProfile}) {
    const axiosPrivate = useAxiosPrivate();

    const [isProfileDescriptionExpanded, setIsProfileDescriptionExpanded] = useState(false);
    const [imageInputEvent, setImageInputEvent] = useState(null);
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);

    const toggleProfileDescription = () => {
        setIsProfileDescriptionExpanded(!isProfileDescriptionExpanded);
    }

    const editProfile = () => {
        setIsProfileModalOpen(true);
    }

    const onSelectImage = (e) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }

        setImageInputEvent(e);
        setIsImageModalOpen(true);
    }

    const uploadImage = (canvas) => {
        canvas.toBlob((blob) => {
            if (blob) {
                // Convert the Blob object to a File object
                const selectedImage = new File([blob], 'image.png', {type: 'image/png'});
                const formData = new FormData();
                formData.append('originalImageFile', selectedImage);
                uploadSpecialistAvatar(formData);

                setIsImageModalOpen(false);
            }
        }, 'image/png');
    }

    const uploadSpecialistAvatar = (formData) => {
        const response
            = axiosPrivate.post(`api/partner/v1/profile/avatar`,
            formData,
            {
                headers: {
                    'Content-Type': 'Form-data',
                }
            })

        response.then(data => {
            profile.avatar = data.data;
            setProfile({...profile});
        }).catch(error => {
            if (error.response) {
                alert(error.response.data.message);
            }
        });
    }

    const toggleState = () => {
        const response = axiosPrivate.put(`api/partner/v1/profile/activity-toggle`)

        response.then(data => {
            profile.isActive = data.data.isActive;
            setProfile({...profile});
        }).catch(error => {
            if (error.response) {
                console.log(error.response.data.message);
            }
        });
    }

    return (
        <div>
            <div className={styles.profileInfoHeader}>
                <div>
                    <input type="file"
                           accept="image/*"
                           onChange={onSelectImage}
                           id={'uploadCoverImageInput'}/>
                    <label htmlFor={'uploadCoverImageInput'}>
                        <img
                            className={styles.userAvatar}
                            src={profile.avatar ? profile.avatar.url : avaPlaceholder}/>
                    </label>
                </div>

                <div>
                    {profile.name} {profile.surname}
                </div>

                <div className={styles.cityName}>
                    {profile.city?.name}
                </div>
            </div>

            <div className={styles.divider}/>

            <div className={styles.profileInfoCenter}>
                <div className={styles.row}>
                    <div className={styles.title}>Стаж работы</div>
                    <div className={styles.value}>{profile.experience}</div>
                </div>

                <div className={styles.row}>
                    <div className={styles.title}>Номер телефона</div>
                    <div className={styles.value}>{PhoneUtils.formatPhoneNumber(profile.phone)}</div>
                </div>

                <div className={styles.row}>
                    <div className={styles.title}>WhatsApp</div>
                    <div className={styles.value}>{PhoneUtils.formatPhoneNumber(profile.whatsappPhone)}</div>
                </div>

                <div className={styles.row}>
                    <div className={styles.title}>Telegram</div>
                    <div className={styles.value}>{PhoneUtils.formatPhoneNumber(profile.telegramPhone)}</div>
                </div>

                <div className={styles.row}>
                    <div className={styles.title}>Email</div>
                    <div className={styles.value}>{profile.email}</div>
                </div>

                <div className={styles.row}>
                    <div className={styles.title}>
                        Статус профиля: {profile.isActive ? 'Онлайн' : 'Оффлайн'}
                        <span className={styles.tooltipWrapper}>
                            <span className={styles.questionIcon}>?</span>
                            <div className={styles.tooltip}>
                                Если тумблер включён, ваш профиль виден на платформе; если выключен — профиль скрыт.
                            </div>
                        </span>
                    </div>
                    <div className={styles.value}>
                        <Switch onChange={toggleState} checked={profile.isActive}
                                checkedIcon={false} uncheckedIcon={false}/>
                    </div>
                </div>
            </div>

            <div className={styles.divider}/>

            <div className={styles.profileDescriptionContainer}>
                <p className={`${styles.profileDescription} ${isProfileDescriptionExpanded ? styles.expanded : ''}`}>
                    {profile.description}
                </p>

                <button id="toggle-btn" onClick={toggleProfileDescription}>
                    {isProfileDescriptionExpanded ? 'Свернуть' : 'Подробнее'}
                </button>
            </div>

            <div className={styles.divider}/>

            <div className={styles.editButtonContainer}>
                <button onClick={editProfile}>
                    Изменить
                </button>
            </div>

            <ImageModal e={imageInputEvent} modalIsOpen={isImageModalOpen}
                        setIsOpen={setIsImageModalOpen} uploadImage={uploadImage}
                        minHeight={300} minWidth={300}/>

            <ProfileModal profile={profile} setProfile={setProfile}
                          modalIsOpen={isProfileModalOpen} setIsOpen={setIsProfileModalOpen}/>
        </div>
    );
}

export default ProfileBlock;
