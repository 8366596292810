import Modal from "react-modal";
import {useRef, useState} from "react";
import ReactCrop, {centerCrop, convertToPixelCrop, makeAspectCrop} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import {setCanvasPreview} from "./setCanvasPreview";

const customStyles = {
    content: {
        maxWidth: '80%',
        width: '100%',
        borderRadius: window.outerWidth <= 480 ? '0' : '16px',
        padding: window.outerWidth <= 480 ? '16px' : '32px',
        margin: window.outerWidth <= 480 ? '0px' : 'auto',
        overflow: 'hidden',
        inset: window.outerWidth <= 480 ? '0' : '40px',
    },
    overlay: {
        zIndex: 1000,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    }
};

function ImageModal({e, modalIsOpen, setIsOpen, uploadImage, minHeight, minWidth}) {

    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);

    const [imgSrc, setImgSrc] = useState('');
    const [crop, setCrop] = useState({});

    function afterOpenModal() {
        const file = e.target.files[0];

        const reader = new FileReader();
        reader.addEventListener('load', () => {
            const imageElement = new Image();
            const imageUrl = reader.result?.toString() || '';
            imageElement.src = imageUrl;
            imageElement.addEventListener("load", (e) => {
                const { naturalWidth, naturalHeight } = e.currentTarget;
                if (naturalWidth < minWidth || naturalHeight < minHeight) {
                    alert(`Минимальный размер изображения ${minWidth}x${minHeight}`);
                    closeModal();
                    return setImgSrc('');
                }
            });

            setImgSrc(imageUrl);
        });

        reader.readAsDataURL(file);
    }

    const onImageLoad = (e) => {
        const {width, height, naturalWidth, naturalHeight} = e.currentTarget;
        const cropWidthInPercent = ((minWidth * naturalHeight) / (minHeight * naturalWidth)) * 100;

        const crop = makeAspectCrop(
            {
                unit: "%",
                width: cropWidthInPercent,
            },
            minWidth / minHeight,
            width,
            height);

        const centeredCrop = centerCrop(crop, width, height);
        setCrop(centeredCrop);
    }

    function closeModal() {
        setImgSrc('');
        setIsOpen(false);
    }

    return (
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            appElement={document.getElementById('root')}
            contentLabel={'Загрузить картинку'}>

            <div style={
                {
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                    width: '100%',
                }
            }>
                {
                    imgSrc &&
                    <div style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                    }}>
                        <ReactCrop
                            crop={crop}
                            keepSelection
                            circularCrop={minWidth === minHeight}
                            aspect={minWidth / minHeight}
                            onChange={(pixelCrop, percentCrop) => setCrop(percentCrop)}>
                            <img
                                ref={imgRef}
                                src={imgSrc}
                                alt="Upload"
                                style={{
                                    width: 'auto',     // Makes the image fill its parent width
                                    height: '500px',    // Makes the image fill its parent height
                                    objectFit: 'cover'
                                }}
                                onLoad={onImageLoad}
                            />
                        </ReactCrop>
                    </div>
                }

                <button
                    style={
                        {
                            width: '150px',
                        }
                    }
                    onClick={() => {
                        setImgSrc('');
                        setCanvasPreview(
                            imgRef.current,
                            previewCanvasRef.current,
                            convertToPixelCrop(crop, imgRef.current.width, imgRef.current.height),
                        );
                        uploadImage(previewCanvasRef.current);
                    }
                    }>
                    Загрузить
                </button>

                {crop &&
                    <canvas
                        ref={previewCanvasRef}
                        style={
                            {
                                display: 'none',
                            }
                        }
                    />
                }

            </div>

        </Modal>
    )
}

export default ImageModal;