import React, {useState} from 'react';
import styles from './css/Contacts.module.css';
import whatsappLogo from './icons/whatsapp-logo.svg';
import telegramLogo from './icons/telegram-logo.svg';
import {Link} from "react-router-dom";

function Contacts({specialist}) {

    const [showFullPhone, setShowFullPhone] = useState(false);

    // Check if myObject exists before accessing its properties
    if (!specialist) {
        return <div>Loading...</div>;
    }

    const getWhatsappLink = () => {
        return `https://wa.me/${specialist.whatsappPhone}`;
    }

    const getTelegramLink = () => {
        return `https://t.me/+${specialist.telegramPhone}`;
    }

    const formatPhone = (phoneNumber, symbolsCount) => {
        if (!phoneNumber) {
            return '';
        }

        // Remove any non-digit characters from the phone number
        const cleanedNumber = phoneNumber.replace(/\D/g, '');

        // Check if the number has the correct length
        if (cleanedNumber.length !== 11) {
            return "Invalid phone number length";
        }

        // Format the phone number according to the specified pattern
        const formattedNumber = `+${cleanedNumber[0]} ${cleanedNumber.substring(1, 4)} ${cleanedNumber.substring(4, 7)} ${cleanedNumber.substring(7, 9)} ${cleanedNumber.substring(9)}`;
        if (symbolsCount) {
            return formattedNumber.slice(0, symbolsCount);
        }
        return formattedNumber;
    }

    const handleFullPhone = () => {
        setShowFullPhone(true);
    }

    return (
        <div className={styles.ContactsContainer}>

            <span>
                {!showFullPhone && formatPhone(specialist.phone, 7)}
                {
                    showFullPhone &&
                    <a href={`tel:${formatPhone(specialist.phone)}`}>
                        {formatPhone(specialist.phone)}
                    </a>
                }
                {!showFullPhone &&
                    <button onClick={handleFullPhone} className={styles.phoneButton}>
                        Показать
                    </button>
                }
            </span>

            {
                specialist.whatsappPhone &&
                <Link to={getWhatsappLink()} className={styles.whatsapp} target={"_blank"}>
                    <img src={whatsappLogo} alt="Go to Whatsapp"/>
                    Whatsapp
                </Link>
            }

            {
                specialist.telegramPhone &&
                <Link to={getTelegramLink()} className={styles.telegram} target={"_blank"}>
                    <img src={telegramLogo} alt="Go to Telegram"/>
                    Telegram
                </Link>
            }
        </div>
    );
}

export default Contacts;