import React from 'react';
import styles from './AboutUs.module.css';

export default function AboutUs() {
    return (
        <div className={styles.mainContainer}>
            {/* Header Section */}
            <header className={styles.aboutUsHeader}>
                <h1>О нас</h1>
                <h2>Платформа, объединяющая талантливых дизайнеров и их клиентов</h2>
            </header>

            <section className={styles.aboutUsSection}>
                <h2>Кто мы?</h2>
                <p>
                    Мы — платформа, объединяющая талантливых дизайнеров интерьера и их потенциальных клиентов. <br/>
                    Наша миссия — вдохновлять и упрощать процесс поиска идеального дизайнера для любого проекта.
                </p>
            </section>

            <section className={styles.aboutUsSection}>
                <h2>Наша цель</h2>
                <p>
                    Мы хотим создать сообщество, где дизайнеры могут показать свои лучшие работы, <br/>
                    а клиенты — найти вдохновение и идеального профессионала для реализации своих идей.
                </p>
            </section>

            <section className={styles.aboutUsSection}>
                <h2>Присоединяйтесь!</h2>
                <p>
                    Если вы дизайнер интерьера, добавляйте свое портфолио и расширяйте круг своих клиентов. <br/>
                    Если вы в поиске дизайнера, начните с изучения портфолио,
                    чтобы найти того, кто идеально подойдет для вашего проекта.
                </p>
            </section>
        </div>
    );
}


