const JWTs = 'jwt_s';

const tokenService = {

    save: (jwts) => {
        localStorage.setItem(JWTs, JSON.stringify(jwts));
    },

    clear: () => {
        localStorage.removeItem(JWTs);
    },

    get: () => {
        return JSON.parse(localStorage.getItem(JWTs));
    }
}

export default tokenService;