import React from 'react';
import styles from './HeroSection.module.css';

const HeroSection = () => {

    const scrollToDesignersSection = () => {
        const element = document.getElementById('designers');
        if (element) {
            element.scrollIntoView({behavior: 'smooth'});
        }
    }

    return (
        <section className={styles.heroSection}>

            <div className={styles.topMiddle}>
                <div className={styles.top}>
                    <div>
                        <span className={styles.mainTitle}>Поиск профессионалов</span><br/>
                        <span className={styles.mainTitle} style={{'color': '#1E1E1E'}}>для вашего пространства</span>
                    </div>

                    <div>
                        <h2>
                            Платформа для поиска<br/>
                            дизайнеров интерьера
                        </h2>
                    </div>
                </div>

                <div className={styles.middle}>

                    <div className={styles.ButtonContainer}>
                        <button onClick={scrollToDesignersSection}>
                            Подобрать профессионала
                        </button>
                    </div>

                    <div className={styles.corners}>
                        <div className={styles.buttonContainerRightCorner}>
                            <div
                                id={styles['curvedCornerTopleft']}></div>
                        </div>

                        <div className={styles.buttonContainerLeftCorner}>
                            <div
                                id={styles['curvedCornerTopleft']}></div>
                        </div>

                        <div className={styles.middleRightTopContainer}>
                            <div
                                id={styles['curvedCornerBottomright']}></div>
                        </div>

                        <div className={styles.bottomLeftCorner}>
                            <div
                                id={styles['curvedCornerBottomright']}></div>
                        </div>


                        <div className={styles.middleRight}>
                            <span>Дизайн, который <br/> говорит о вас</span>
                        </div>
                    </div>

                </div>
            </div>


            <div className={styles.bottom}>
                <h3>
                    Сегодня 260 человек ищут лучших дизайнеров интерьера
                </h3>
            </div>
        </section>
    );
}

export default HeroSection;