import { v4 as uuidv4 } from 'uuid';

const UUID_KEY = 'abdiz_uuid';

const uuidService = {
    generateUUID: () => {
        const newUUID = uuidv4();
        localStorage.setItem(UUID_KEY, newUUID);
        return newUUID;
    },

    getUUID: () => {
        let uuid = localStorage.getItem(UUID_KEY);
        if (!uuid) {
            uuid = uuidService.generateUUID();
        }
        return uuid;
    }
};

export default uuidService;