import {useParams} from "react-router-dom";
import styles from "../css/ProjectPage.module.css";
import React, {useEffect, useState} from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import RoomModal from "./modal/RoomModal";
import ImageModal from "./image/ImageModal";
import ProjectInfoModal from "./modal/ProjectInfoModal";
import Switch from "react-switch";

const ImageModalType = {
    ROOM_IMAGE: 'roomImage',
    COVER_IMAGE: 'coverImage'
}

function ProjectPage() {
    const {projectId} = useParams();
    const [roomsList, setRoomsList] = useState([]);
    const [modalRoom, setModalRoom] = useState(null);
    const [roomImages, setRoomImages] = useState([]);
    const [coverImage, setCoverImage] = useState(null);
    const [activeRoomIndex, setActiveRoomIndex] = React.useState(-1);

    const [addRoomModalIsOpen, setAddRoomModalOpen] = useState(false);
    const [projectInfoModalIsOpen, setProjectInfoModalIsOpen] = useState(false);
    const [roomsListChanged, setRoomsListChanged] = useState(false);
    const [roomDataChanged, setRoomDataChanged] = useState(false);
    const [isProjectInfoChanged, setIsProjectInfoChanged] = useState(false);

    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [imageInputEvent, setImageInputEvent] = useState(null);
    const [imageModalType, setImageModalType] = useState(null);

    const axiosPrivate = useAxiosPrivate();
    const [project, setProject] = useState(null);

    useEffect(() => {
        const fetchProject = async () => {
            const response = await axiosPrivate.get(`api/partner/v1/projects/${projectId}`)
            return response.data;
        }

        const response = fetchProject();
        response.then(data => {
            setProject(data.project);
            setCoverImage(data.coverImage);
            setRoomsListChanged(true);
        }).catch(error => {
            if (error.response) {
                console.log(error.response.data.message);
            }
        });
    }, [isProjectInfoChanged]);

    useEffect(() => {
        if (!project) {
            return;
        }

        const fetchRooms = async () => {
            const response = await axiosPrivate.get(`api/partner/v1/projects/${projectId}/rooms`)
            return response.data;
        }

        const roomsResponse = fetchRooms();
        roomsResponse.then(data => {
            setRoomsList(data.rooms);
            setActiveRoomIndex(activeRoomIndex < 0 ? 0 : activeRoomIndex);
            setRoomDataChanged(!roomDataChanged);
        }).catch(error => {
            if (error.response) {
                console.log(error.response.data.message);
            }
        });
    }, [roomsListChanged]);

    useEffect(() => {
        if (activeRoomIndex < 0 || roomsList.length === 0) {
            return;
        }

        const selectedRoom = roomsList[activeRoomIndex];
        const response = fetchRoomDetails(selectedRoom.id);
        response.then(data => {
            setRoomImages(data.images);
        }).catch(error => {
            if (error.response) {
                console.log(error.response.data.message);
            }
        });
    }, [activeRoomIndex, roomDataChanged]);

    const fetchRoomDetails = async (roomId) => {
        const response = await axiosPrivate.get(`api/partner/v1/projects/${projectId}/rooms/${roomId}`)
        return response.data;
    }

    const uploadImage = (canvas) => {
        canvas.toBlob((blob) => {
            if (blob) {
                // Convert the Blob object to a File object
                const selectedImage = new File([blob], 'image.png', {type: 'image/png'});
                const formData = new FormData();
                formData.append('originalImageFile', selectedImage);

                if (imageModalType === ImageModalType.ROOM_IMAGE) {
                    uploadRoomImage(formData);
                } else if (imageModalType === ImageModalType.COVER_IMAGE) {
                    uploadCoverImage(formData);
                }


                setIsImageModalOpen(false);
            }
        }, 'image/png');
    }

    const uploadRoomImage = (formData) => {
        const selectedRoom = roomsList[activeRoomIndex];
        const response
            = axiosPrivate.post(`api/partner/v1/projects/${projectId}/rooms/${selectedRoom.id}/images`,
            formData,
            {
                headers: {
                    'Content-Type': 'Form-data',
                }
            })

        response.then(data => {
            setRoomDataChanged(!roomDataChanged);
        }).catch(error => {
            if (error.response) {
                alert(error.response.data.message);
            }
        });
    }

    const deleteRoomImage = (imageId) => {
        const userConfirmed = window.confirm("Вы уверены, что хотите удалить фотографию?");

        if (userConfirmed) {
            const selectedRoom = roomsList[activeRoomIndex];
            const response
                = axiosPrivate.delete(`api/partner/v1/projects/${projectId}/rooms/${selectedRoom.id}/images/${imageId}`)

            response.then(data => {
                setRoomDataChanged(!roomDataChanged);
            }).catch(error => {
                if (error.response) {
                    alert(error.response.data.message);
                }
            });
        }
    }

    const uploadCoverImage = (formData) => {
        const response = axiosPrivate.put(`api/partner/v1/projects/${projectId}/cover-image`,
            formData,
            {
                headers: {
                    'Content-Type': 'Form-data',
                }
            })

        response.then(data => {
            setCoverImage(data.data);
        }).catch(error => {
            if (error.response) {
                alert(error.response.data.message);
            }
        });
    }

    const deleteCoverImage = () => {
        const userConfirmed = window.confirm("Вы уверены, что хотите удалить обложку?");

        if (userConfirmed) {
            const response = axiosPrivate.delete(`api/partner/v1/projects/${projectId}/cover-image`)

            response.then(data => {
                setCoverImage(null);
            }).catch(error => {
                if (error.response) {
                    console.log(error.response.data.message);
                }
            });
        }
    }

    const togglePublished = () => {
        const response = axiosPrivate.put(`api/partner/v1/projects/${projectId}/publish-toggle`)

        response.then(data => {
            setProject(data.data.project);
        }).catch(error => {
            if (error.response) {
                console.log(error.response.data.message);
            }
        });
    }

    const onSelectImage = (e) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }

        setImageInputEvent(e);
        setIsImageModalOpen(true);
    }

    const onSelectRoomImage = (e) => {
        setImageModalType(ImageModalType.ROOM_IMAGE);
        onSelectImage(e);
    }

    const onSelectCoverImage = (e) => {
        setImageModalType(ImageModalType.COVER_IMAGE);
        onSelectImage(e);
    }

    const openRoomModal = (selectedRoom) => {
        setModalRoom(selectedRoom);
        setAddRoomModalOpen(true);
    }

    if (!project) {
        return <div>Loading...</div>;
    }

    return (
        <div className={styles.mainContainer}>
            <h1>{project.name}</h1>

            <div className={styles.innerContainer}>
                <div className={styles.roomsContainer}>
                    <div className={styles.containerHeader}>
                        <h2>
                            Фотографии
                        </h2>
                    </div>

                    <div className={styles.divider}/>

                    <button className={styles.addButton} onClick={() => openRoomModal(null)}>
                        Добавить комнату
                    </button>

                    <div className={styles.RoomTypes}>
                        {
                            roomsList.map((room, index) => {
                                return (
                                    <div key={room.id}
                                         className={`${styles.RoomType} ${activeRoomIndex === index ? styles.active : styles.inactive}`}
                                         onClick={() => {
                                             setActiveRoomIndex(index);
                                         }}>
                                        <h3>{room.name}</h3>
                                    </div>
                                );
                            })
                        }
                    </div>

                    {
                        roomsList.length === 0 &&
                        <div className={styles.noRooms}>
                            Для добавления фотографии добавьте комнату
                        </div>
                    }

                    {
                        roomsList.length > 0 &&
                        <div>
                            <div className={styles.roomImagesTable}>
                                {roomImages.map(roomImage => {
                                    return (
                                        <div
                                            key={`roomImage-${roomImage.id}`}
                                            className={styles.roomImage}>
                                            <img src={roomImage.url}/>

                                            <button
                                                onClick={() => {
                                                    deleteRoomImage(roomImage.id);
                                                }}
                                                className={styles.deleteButton}/>
                                        </div>
                                    );
                                })}

                                <div className={styles.roomImage}>
                                    <input type={'file'}
                                           accept={'image/*'}
                                           onChange={onSelectRoomImage}
                                           id={'uploadImageInput'}/>
                                    <label htmlFor={'uploadImageInput'}
                                           className={styles.uploadImageButton}
                                           style={{
                                               height: '100%',
                                               aspectRatio: '4/3'
                                           }}>
                                        <button type="button"
                                                onClick={() => document.getElementById('uploadImageInput').click()}>
                                            Загрузить фото
                                        </button>
                                    </label>
                                </div>
                            </div>
                        </div>
                    }

                    {
                        roomsList[activeRoomIndex]
                        &&
                        <div className={styles.editButtonContainer}>
                            <button onClick={() => {
                                openRoomModal(roomsList[activeRoomIndex]);
                            }}>
                                Редактировать комнату
                            </button>
                        </div>
                    }

                </div>

                <div className={styles.rightContainers}>
                    <div className={styles.rightContainer}>
                        <div className={styles.containerHeader}>
                            <h2>
                                Обложка проекта
                            </h2>
                        </div>

                        <div className={styles.divider}/>

                        {
                            !coverImage &&
                            <div className={styles.uploadImageButton}>
                                <input type={'file'}
                                       accept={'image/*'}
                                       onChange={onSelectCoverImage}
                                       id={'uploadCoverImageInput'}/>
                                <label htmlFor={'uploadCoverImageInput'}>
                                    <button type="button"
                                            onClick={() => document.getElementById('uploadCoverImageInput').click()}>
                                        Загрузить обложку
                                    </button>
                                </label>
                            </div>
                        }

                        {
                            coverImage &&
                            <div className={styles.coverImageContainer}>
                                <img src={coverImage.url}/>
                                <button
                                    onClick={deleteCoverImage}
                                    className={styles.deleteButton}/>
                            </div>
                        }

                    </div>

                    <div className={styles.rightContainer}>
                        <div className={styles.containerHeader}>
                            <h2>
                                О проекте
                            </h2>
                        </div>

                        <div className={styles.divider}/>

                        <div className={styles.projectInfo}>
                            <div>
                                <span className={styles.projectInfoKey}>
                                    Тип проекта:
                                </span>
                                <span>
                                    {project.premisesType.name}
                                </span>
                            </div>

                            <div>
                                <span className={styles.projectInfoKey}>
                                    Общая площадь:
                                </span>
                                <span>
                                    {project.squareMeters} кв. м.
                                </span>
                            </div>

                            <div>
                                <span className={styles.projectInfoKey}>
                                    Статус: {project.state.name}
                                </span>
                                <span>
                                    <Switch onChange={togglePublished} checked={project.state.key === 'PUBLISHED'}
                                            checkedIcon={false} uncheckedIcon={false}/>
                                </span>
                            </div>
                        </div>

                        <div className={styles.editButtonContainer}>
                            <button onClick={()=>{
                                setProjectInfoModalIsOpen(true);
                            }}>
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <RoomModal project={project} room={modalRoom} modalIsOpen={addRoomModalIsOpen}
                       setIsOpen={setAddRoomModalOpen} setRoomsListChanged={setRoomsListChanged}
                       roomsListChanged={roomsListChanged}/>

            <ImageModal e={imageInputEvent} modalIsOpen={isImageModalOpen}
                        setIsOpen={setIsImageModalOpen} uploadImage={uploadImage}
                        minHeight={480} minWidth={640}/>

            <ProjectInfoModal
                project={project}
                modalIsOpen={projectInfoModalIsOpen} setIsOpen={setProjectInfoModalIsOpen}
                newProjectAdded={isProjectInfoChanged} setNewProjectAdded={setIsProjectInfoChanged}/>

        </div>
    );
}

export default ProjectPage;
