import React, {useState} from "react";
import styles from "./css/Portfolio.module.css";
import ScrollContainer from 'react-indiana-drag-scroll'
import WorkDetails from "./WorkDetails";
import imagePlaceholder from "./icons/missing-image.svg";

function Portfolio({projects}) {

    const [openWorkDetails, setOpenWorkDetails] = useState(false);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [selectedProject, setSelectedProject] = useState({});

    // Check if myObject exists before accessing its properties
    if (!projects) {
        return <div>Loading...</div>;
    }

    function openModal() {

    }

    const handleMouseOver = (e, workId) => {
        // e.('WatchButtonContainer').style.display = 'block';
        const hiddenContent = document.getElementById('WatchButtonContainer' + workId);
        if (hiddenContent) {
            // Do something with the found element
            hiddenContent.style.visibility = 'visible';
        }
    };

    const handleMouseOut = (e, workId) => {
        // e.('WatchButtonContainer').style.display = 'block';
        const hiddenContent = document.getElementById('WatchButtonContainer' + workId);
        if (hiddenContent) {
            // Do something with the found element
            hiddenContent.style.visibility = 'hidden';
        }
    };

    const openWorkDetailsDialog = (e, project) => {
        // alert('Открываем детали работы с id ' + workId);
        setSelectedProject(project);
        setIsOpen(true);
    }

    return (
        <section className={styles.PortfolioSection}>
            <h2>Портфолио</h2>

            <h3>Жилые помещения</h3>

            <ScrollContainer className={styles.HorizontalScrollContainer}>
                <div className={styles.ScrollContent}>

                    {
                        projects.map((work) => {
                            return (
                                <div key={work.id} className={styles.WorkCard}>

                                    <div className={styles.ImageContainer}
                                         onMouseOver={(e) => handleMouseOver(e, work.id)}
                                         onMouseOut={(e) => handleMouseOut(e, work.id)}
                                         onClick={(e) => openWorkDetailsDialog(e, work)}>

                                        <div id={'WatchButtonContainer' + work.id}
                                             className={styles.WatchButtonContainer}>
                                            <div className={styles.watchButton}>
                                                Смотреть
                                            </div>
                                        </div>

                                        <img src={work.coverImage ? `${work.coverImage.url}` : imagePlaceholder}
                                             alt="Дизайн квартиры"/>
                                    </div>

                                    <div className={styles.WorkDescription}>
                                        <span className={styles.name}>{work.name}</span>
                                        <span className={styles.squareMeters}>{work.squareMeters} кв.м</span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </ScrollContainer>

            <WorkDetails project={selectedProject} modalIsOpen={modalIsOpen} setIsOpen={setIsOpen}/>

        </section>
    );
}

export default Portfolio;