import styles from "../css/ProjectsBlock.module.css";
import projectPlaceHolder from "../icons/project-placeholder.png";
import React from "react";
import {useNavigate} from "react-router-dom";


function ProjectCard({project, coverImage}) {
    const navigate = useNavigate();

    const openProjectPage = () => {
        navigate(`/partner/projects/${project.id}`);
    }

    return (
        <div className={styles.workRow} onClick={openProjectPage}>
            <img src={coverImage ? coverImage.url : projectPlaceHolder}/>

            <div className={styles.projectTitle}>{project.name}</div>
        </div>
    );
}

export default ProjectCard;
