import React from 'react';

function NextIcon({onClick}) {
    return (
        <div onClick={onClick} style={{cursor: "pointer"}}>
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="18" cy="18" r="17.5" stroke="#7D7D7D"/>
                <path d="M15 24L21 18L15 12" stroke="#7D7D7D" strokeWidth="1.5" strokeLinecap="round"
                      strokeLinejoin="round"/>
            </svg>
        </div>

    )
}

export default NextIcon;