import React from 'react';
import styles from './css/BioContainer.module.css';

function BioContainer({specialistDetails}) {

    let specialist = specialistDetails?.specialist;

    // Check if myObject exists before accessing its properties
    if (!specialist) {
        return <div>Loading...</div>;
    }

    return (
        <div className={styles.BioContainer}>
            <div className={styles.NameContainer}>
                <div className={styles.AvatarContainerForMobile}>
                    {
                        specialistDetails.avatar &&
                        <img
                            src={`${specialistDetails.avatar.url}`}/>
                    }
                </div>

                <h1>
                    {specialist.name} {specialist.surname}
                </h1>
            </div>

            <div className={styles.description}>
                <p>
                    {specialist.description}
                </p>
            </div>

            <div className={styles.StatisticsContainer}>

                {
                    specialist.experience &&
                    <div>
                        <span className={styles.statNumber}>
                            {specialist.experience}
                        </span>

                            <span className={styles.statDescription}>
                            опыта работы
                        </span>
                    </div>
                }

                <div>
                    <span className={styles.statNumber}>
                        30+
                    </span>

                    <span className={styles.statDescription}>
                        проектов
                    </span>
                </div>

                <div>
                    <span className={styles.statNumber}>
                        25
                    </span>
                    <span className={styles.statDescription}>
                        довольных клиентов
                    </span>
                </div>
            </div>
        </div>
    );
}

export default BioContainer;