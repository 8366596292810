import axios from 'axios';
import uuidService from "../components/Util/uuidService";

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

// Add a request interceptor to add token if available
apiClient.interceptors.request.use(
    config => {
        config.headers['uuid'] = `${uuidService.getUUID()}`;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

export default apiClient;