import Modal from "react-modal";
import React from "react";
import styles from "../../css/ProjectsBlock.module.css";
import {useForm} from "react-hook-form";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";

const customStyles = {
    content: {
        width: '628px',
        height: '300px',
        borderRadius: window.outerWidth <= 480 ? '0' : '16px',
        padding: window.outerWidth <= 480 ? '16px' : '32px',
        margin: window.outerWidth <= 480 ? '0px' : 'auto',
        overflow: 'hidden',
        inset: window.outerWidth <= 480 ? '0' : '40px',
    },
    overlay: {
        zIndex: 1000,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    }
};

const roomTypes = [
    {key: "BEDROOM", value: "Спальня"},
    {key: "LIVING_ROOM", value: "Гостиная"},
    {key: "KITCHEN", value: "Кухня"},
    {key: "KITCHEN_STUDIO", value: "Кухня-студия"},
    {key: "BATHROOM", value: "Ванная"},
    {key: "TOILET", value: "Туалет"},
    {key: "SANUZEL", value: "Санузел"},
    {key: "HALLWAY", value: "Прихожая"},
    {key: "BALCONY", value: "Балкон"},
    {key: "TERRACE", value: "Терраса"},
    {key: "STORAGE", value: "Кладовая"},
    {key: "WARDROBE", value: "Гардероб"},
    {key: "CHILD_ROOM", value: "Детская"},
    {key: "GARAGE", value: "Гараж"},
    {key: "CORRIDOR", value: "Коридор"},
    {key: "CABINET", value: "Кабинет"}
];

function RoomModal({
                          project, room, modalIsOpen, setIsOpen,
                          roomsListChanged, setRoomsListChanged
                      }) {
    const axiosPrivate = useAxiosPrivate();
    const {
        register, setValue, setError,
        handleSubmit, formState
    }
        = useForm({
        mode: 'onChange'
    });
    const {errors} = formState;

    function closeModal() {
        setIsOpen(false);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        // subtitle.style.color = '#f00';
        if (room) {
            // console.log(room);
            setValue('name', room.name);
            setValue('roomType', room.key);
        } else {
            const defaultRoomType = roomTypes[0];
            setValue('name', defaultRoomType.value);
            setValue('roomType', defaultRoomType.key);
        }
    }

    const postRoom = async (data) => {
        const response
            = await axiosPrivate.post(`/api/partner/v1/projects/${project.id}/rooms`, data);
        return response.data;
    }

    const putRoom = async (data) => {
        const response
            = await axiosPrivate.put(`/api/partner/v1/projects/${project.id}/rooms/${room.id}`, data);
        return response.data;
    }

    const deleteRoom = async () => {
        const userConfirmed = window.confirm("Вы уверены, что хотите удалить комнату? Все данные будут потеряны.");
        if (!userConfirmed) {
            return;
        }

        await axiosPrivate.delete(`/api/partner/v1/projects/${project.id}/rooms/${room.id}`);
        setRoomsListChanged(!roomsListChanged);
        closeModal();
    }

    function submit(data) {
        const response = room ? putRoom(data) : postRoom(data);

        response.then(data => {
            setRoomsListChanged(!roomsListChanged);
            closeModal();
        }).catch(error => {
            if (error.response) {
                alert(error.response.data.message);
            }
        });
    }

    const onRoomTypeSelected = (event) => {
        const selectedRoomType = roomTypes.find(roomType => roomType.key === event.target.value);
        setValue('name', selectedRoomType.value, {
            shouldValidate: true, // This will trigger validation after setting the value
        })
    }

    return (
        <div className={styles.addProjectModalContainer}>
            <Modal isOpen={modalIsOpen}
                   onAfterOpen={afterOpenModal}
                   onRequestClose={closeModal}
                   style={customStyles}
                   appElement={document.getElementById('root')}
                   contentLabel={'Добавить проект'}>
                <div>
                    <h2>
                        {
                            !room && 'Добавить комнату'
                        }
                        {
                            room && 'Редактировать комнату'
                        }
                    </h2>

                    <form onSubmit={handleSubmit(submit)}>

                        <div className={styles.formControl}>
                            <input id='name' type='text'
                                   placeholder={'Название комнаты'}
                                   {...register('name', {
                                       required:
                                           'Название комнаты не может быть пустым'
                                   })} />
                            <p className={styles.error}> {errors.name?.message}</p>
                        </div>

                        <div className={styles.formControl}>
                            <select id={'roomType'} {...register('roomType', {
                                required: 'Выберите тип комнаты',
                            })} onChange={(event) => {
                                onRoomTypeSelected(event)
                            }}>
                                {roomTypes.map((roomType) => {
                                    return (
                                        <option key={roomType.key} value={roomType.key}>
                                            {roomType.value}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>

                        {
                            !room &&
                            <button className={styles.addButton} type={'submit'}>
                                Создать
                            </button>
                        }

                        {
                            room &&
                            <div style={{
                                float: 'right'
                            }}>
                                <button className={styles.deleteButton}
                                        type={'button'}
                                        onClick={deleteRoom}>
                                    Удалить
                                </button>

                                <button className={styles.addButton} type={'submit'}>
                                    Сохранить
                                </button>
                            </div>
                        }
                    </form>

                </div>

            </Modal>
        </div>
    );
}

export default RoomModal;
