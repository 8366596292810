export class PhoneUtils {
    // Formats a phone number into a specific pattern
    static formatPhoneNumber = (phone) => {
        if (!phone) return '';
        const cleaned = phone.replace(/\D/g, ''); // Remove non-digit characters
        if (cleaned.length !== 11 || !cleaned.startsWith('7')) return phone;

        return `+7 (${cleaned.slice(1, 4)}) ${cleaned.slice(4, 7)}-${cleaned.slice(7, 9)}-${cleaned.slice(9, 11)}`;
    }

    // Validates if a phone number matches a specific format
    static isValidPhoneNumber = (phone) => {
        phone = phone?.replace(/\D/g, '');
        return phone?.length === 11 || phone?.length <= 1;
    }
}