import apiClient from './index';

const specialistService = {
    getSpecialistDetails: async (id) => {
        try {
            const response = await apiClient.get(`/api/public/v1/specialists/${id}/details`);
            return response.data;
        } catch (error) {
            // Handle error
            throw error;
        }
    },

    getSpecialistsCards: async () => {
        try {
            const response = await apiClient.get(`/api/public/v1/specialists/cards`);
            return response.data;
        } catch (error) {
            // Handle error
            throw error;
        }
    },
};

export default specialistService;