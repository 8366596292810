import apiClient from './index';

const authService = {
    login: async (email, password) => {
        const body = {
            email: email,
            password: password
        };
        const response = await apiClient.post(`/api/auth/v1/sign-in`, body);
        return response.data;
    },

    signUp: async (data) => {
        const response = await apiClient.post(`/api/auth/v1/sign-up`, data);
        return response.data;
    },

    requestEmailCode: async (email, purpose) => {
        const body = {
            email: email,
            purpose: purpose
        };

        const response = await apiClient.post(`/api/auth/v1/email-verification/request-code`,
            body);
        return response.data;
    },
};

export default authService;