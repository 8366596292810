import {createContext, useEffect, useState} from "react";
import tokenService from "../components/Util/tokenService";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState(null);
    const [loading, setLoading] = useState(true); // Loading state

    useEffect(() => {
        const jwts = tokenService.get();
        if (jwts) {
            setAuth(jwts);
        } else {
            setAuth(null); // Clear auth state if token is expired or not present
        }
        setLoading(false); // Mark loading as complete
    }, []);

    // If loading, show a loading indicator or fallback UI
    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;